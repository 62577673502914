// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Utilities
import { map, size } from 'lodash';

// View
import MoleculesBlogList from '../../Molecules/BlogList';


// Assets
import featureImage from '../../../assets/images/feature-image.jpg';
import featureImage2 from '../../../assets/images/feature-image2.jpg';
import featureImage3 from '../../../assets/images/feature-image3.jpg';



class OrganismBlogListWrap extends React.Component {
  render() {
    const { list } = this.props;
    // If list is empty
    if (size(list) < 1) {
      return null;
    }
    return (
      <div className="organism-blog-list-wrap">
        {
          map(list, function (row, index) {
            const { image, category, title, description, link, createdAt, autherName } = row;
            return (
              <MoleculesBlogList
                kay={index}
                image={image}
                category={category}
                title={title}
                description={description}
                link={link}
                autherName={autherName}
                createdAt={createdAt}
              />
            )
          })
        }
      </div>
    )
  }
}

OrganismBlogListWrap.defaultProps = {
  list: [
    {
      image: featureImage,
      category: 'ENTERPRISE',
      title: 'Autodesk looks to future of 3D printing with Project Escher',
      description: 'Like so many organizations these days, Autodesk is a company in transition. It was until recently a traditional boxed software company selling licenses. Today, it’s moving to a subscription model. Yet its own business model disruption is only part of the story — and…',
      link: '',
      autherName: 'Mike Butcher',
      createdAt: '2 days ago',
    },
    {
      image: featureImage2,
      category: 'TRENDING',
      title: '6 insights into the French Fashion landscape',
      description: 'Like so many organizations these days, Autodesk is a company in transition. It was until recently a traditional boxed software company selling licenses. Today, it’s moving to a subscription model. Yet its own business model disruption is only part of the story — and…',
      link: '',
      autherName: 'Mike Butcher',
      createdAt: '2 days ago',
    },
    {
      image: featureImage3,
      category: 'STARTUPS',
      title: 'Lyft launching cross-platform service this week',
      description: 'Like so many organizations these days, Autodesk is a company in transition. It was until recently a traditional boxed software company selling licenses. Today, it’s moving to a subscription model. Yet its own business model disruption is only part of the story — and…',
      link: '',
      autherName: 'Mike Butcher',
      createdAt: '2 days ago',
    }
  ]
}

OrganismBlogListWrap.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      category: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      autherName: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ).isRequired,
}

export default OrganismBlogListWrap;