/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import { Link } from 'gatsby';

class AtomPagination extends React.Component {
  render() {

    return (
      <div className="atom-pagination">
        <ul>
          <li className="prev"><Link to="/">«</Link></li>
          <li><Link to="/">1</Link></li>
          <li><Link to="/">2</Link></li>
          <li className="active"><Link to="/">3</Link></li>
          <li><Link to="/">4</Link></li>
          <li><Link to="/">5</Link></li>
          <li className="next"><Link to="/">»</Link></li>
        </ul>
      </div>
    )
  }
}


export default AtomPagination;

