// Basics
import React from 'react';
import './index.scss';
import { Grid, Image } from 'semantic-ui-react'
import { Link } from 'gatsby';

// View
import AtomImage from '../../Atoms/Image';

class MoleculesBlogList extends React.Component {
  render() {
    const { image, category, title, description, link, autherName, createdAt } = this.props;
    return (
      <div className="molecules-blog-list">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={5} tablet={5} mobile={5}>
              {
                image && (
                <div className="feature-image">
                  <Image src={image} />
                </div>
              )
}
            </Grid.Column>
            <Grid.Column computer={11} tablet={11} mobile={11}>
              <div className="blog-list-content">
                {
                  category && (
                  <div className="tags">
                    <span className="tag">{category}</span>
                  </div>
                )
}

                {
                  title &&
                  <h3>{title}</h3>
                }

                {
                  description && (
                  <p className="small-text">
                    {description}
                    <Link to={link} className="more">Read More</Link>
                  </p>
                )
}
                {
                  autherName && (
                  <span className="auther">
by
                    <a href="#">{autherName}</a>
                    {' '}
,
                    {createdAt}
                  </span>
                )
}

              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default MoleculesBlogList;
