
// Basics
import React from 'react';
import './index.scss';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Utilities
import { map, size } from 'lodash';

class AtomCategoryFilter extends React.Component {
  render() {
    const { list } = this.props;
    // If list is empty
    if (size(list) < 1) {
      return null;
    }
    return (
      <div className="category-filter">
        <ul>
          {
            map(list, function (row, index) {
              const { Category, link } = row;
              return (
                <li kay={index}><Link to={link}>{Category}</Link></li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

AtomCategoryFilter.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      Category: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ).isRequired,
}

export default AtomCategoryFilter;

